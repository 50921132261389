import React, { useContext } from 'react'
import { Appcontext } from '../../../Contextapi/AppContext'

function Modal() {
    const { armodelState, setarmodelState, setariframestate, setarcomponent } = useContext(Appcontext);

    const closeHandler = (e) => {
        const id = e.target.id;
        if (id === 'ar-model-component') {
            setarmodelState(false)
        }
    }
    const ariframhandler = (type) => {

        // // if (type === 'furniture') {

        //     const url = 'https://3-d-preview-next-web.vercel.app/';
        //     window.open(url, '_blank');
        //     return 0;
        // }

        setarcomponent(type)
        setariframestate(true)
        setarmodelState(false)
    }
    return (
        <div onClick={closeHandler} id='ar-model-component' className={`${armodelState ? 'fixed' : 'hidden'}  z-50 inset-0 flex flex-col justify-center items-center px-20`}>
            <div className='bg-[#373737]/80 rounded-xl p-10 backdrop-blur-xl'>
                <div className='flex flex-col items-center gap-5'>
                    <img className='w-28 h-28' src="/assets/svg/logo.svg" alt="logo" />
                    <p className='text-white font-earth-orbit-regular text-center capitalize text-xl pb-5'>SELECT A CATEGORY</p>
                </div>
                <div className="flex flex-wrap justify-center overflow-x-hidden w-full gap-x-20 gap-y-2  ">
                    <ARcard handler={ariframhandler} />
                </div>
            </div>
        </div>
    )
}

export default Modal



const ARcard = ({ handler }) => {
    // const [ModalState, setModalState] = useState(false);
    // const [ModalType, setModalType] = useState('')
    const FooterContent = [
        {
            id: 5,
            imgsource: '/favicon.png',
            title: 'FaceAI',
            modalname: 'faceais',
            desc: 'Try On',
        },
        {
            id: 6,
            imgsource: '/favicon.png',
            title: 'Furniture 2.O',
            modalname: 'FurnitureCust',
            desc: 'link',
        },
        {
            id: 1,
            imgsource: '/assets/svg/cosmet.svg',
            title: 'Cosmetics',
            modalname: 'cosmatics',
            desc: 'Try On'
        },
        {
            id: 2,
            imgsource: '/assets/svg/jwell.svg',
            title: 'Jewellery',
            modalname: 'jwellery',
            desc: 'Try On'
        },
        {
            id: 3,
            imgsource: '/assets/svg/hand.svg',
            title: 'Hand jwellery',
            modalname: 'hands',
            desc: 'Try On',
        },
        // {
        //     id: 4,
        //     imgsource: '/assets/svg/hand.svg',
        //     title: 'Furniture',
        //     modalname: 'furniture',
        //     desc: 'link',
        // },
       
       


    ]


    return (
        <>
            {FooterContent.map((item, index) =>
                <div onClick={() => { handler(item.modalname) }} key={index} className='h-[120px] md:h-[250px] lg:h-[250px] xl:h-[250px] w-[180px] bg-[#494949]/80 backdrop-blur-[10px] rounded-[10px] p-5 flex flex-col items-start justify-between cursor-pointer'>
                    {item.desc === 'link' ?
                        <FaChair width={50} height={50} className={'text-primary'} />
                        :
                        <img className='w-10 md:w-16 lg:w-16 xl:w-16 aspect-square object-contain' src={item.imgsource} alt="" />

                    }
                    <div className='flex justify-between gap-3 w-full '>
                        <p className='capitalize text-white text-md whitespace-nowrap font-bold'>{item.title}</p>
                        {item.desc === 'Try On' ?

                            <img className='w-8 active:opacity-50 cursor-pointer h-auto' src="/assets/svg/right-arrow.svg" alt="" />

                            // <Button variant={'primary'} onClick={() => { ArframChanges(item.modalname, item.id) }} rounded={true} className={'uppercase '}> {item.desc}</Button>
                            :
                            // <p className='text-primary font-bold text-2xl animate-pulse'>{item.desc}</p>
                            <AiOutlineLink width={30} height={30} className={'text-white'} />
                        }
                    </div>
                </div>
            )}
            {/* <ArComponents type={ModalType} state={ModalState} Handler={setModalState} typeHandler={setModalType} /> */}
        </>
    )
}



function AiOutlineLink(props) {
    return <svg stroke="currentColor" fill="currentColor" strokeWidth={0} viewBox="0 0 1024 1024" height="1em" width="1em" {...props}><path d="M574 665.4a8.03 8.03 0 0 0-11.3 0L446.5 781.6c-53.8 53.8-144.6 59.5-204 0-59.5-59.5-53.8-150.2 0-204l116.2-116.2c3.1-3.1 3.1-8.2 0-11.3l-39.8-39.8a8.03 8.03 0 0 0-11.3 0L191.4 526.5c-84.6 84.6-84.6 221.5 0 306s221.5 84.6 306 0l116.2-116.2c3.1-3.1 3.1-8.2 0-11.3L574 665.4zm258.6-474c-84.6-84.6-221.5-84.6-306 0L410.3 307.6a8.03 8.03 0 0 0 0 11.3l39.7 39.7c3.1 3.1 8.2 3.1 11.3 0l116.2-116.2c53.8-53.8 144.6-59.5 204 0 59.5 59.5 53.8 150.2 0 204L665.3 562.6a8.03 8.03 0 0 0 0 11.3l39.8 39.8c3.1 3.1 8.2 3.1 11.3 0l116.2-116.2c84.5-84.6 84.5-221.5 0-306.1zM610.1 372.3a8.03 8.03 0 0 0-11.3 0L372.3 598.7a8.03 8.03 0 0 0 0 11.3l39.6 39.6c3.1 3.1 8.2 3.1 11.3 0l226.4-226.4c3.1-3.1 3.1-8.2 0-11.3l-39.5-39.6z" /></svg>;
}


function FaChair(props) {
    return <svg stroke="currentColor" fill="currentColor" strokeWidth={0} viewBox="0 0 448 512" height="1em" width="1em" {...props}><path d="M112 128c0-29.5 16.2-55 40-68.9V256h48V48h48v208h48V59.1c23.8 13.9 40 39.4 40 68.9v128h48V128C384 57.3 326.7 0 256 0h-64C121.3 0 64 57.3 64 128v128h48zm334.3 213.9l-10.7-32c-4.4-13.1-16.6-21.9-30.4-21.9H42.7c-13.8 0-26 8.8-30.4 21.9l-10.7 32C-5.2 362.6 10.2 384 32 384v112c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V384h256v112c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V384c21.8 0 37.2-21.4 30.3-42.1z" /></svg>;
}
